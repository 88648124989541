<template>
    <input type="search" class="table-title__input-filter" placeholder="Search/Filter" @input="debounceFilter">
</template>

<script>
import debounce from "lodash/debounce";

export default {
    props: ['modelValue'],
    emits: ['update:modelValue'],
    methods: {
        debounceFilter: debounce(function (e) {
            return this.$emit('update:modelValue', e.target.value);
        }, 500),
    },
}
</script>