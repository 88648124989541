<template>
    <div class="modal-delete">
        <div class="modal-delete__content">
            <h3 class="modal-delete__title">
                You Are About To Delete This Record
            </h3>

            <form class="modal-delete__form" @submit.prevent="deleteRecord">
                <span class="modal-delete__form-legend">
                    Please enter the word <strong>"DELETE"</strong> in the field to continue
                </span>
                <div class="modal-delete__text-confirm">
                    <input type="text" class="modal-delete__input-confirm" placeholder="Type “DELETE”"
                        v-model="confirmationText">

                    <div class="modal-delete__buttons">
                        <button :class="[disabledButton ? 'button button--disabled' : 'button button--danger']"
                            :disabled="disabledButton">
                            Delete It
                        </button>
                        <a href="#" class="button button--primary" @click.prevent="hiddenModal">
                            Cancel
                        </a>
                    </div>
                </div>
            </form>
        </div>
    </div>

    <Notifications ref="notifications" />
</template>

<script setup>
import { ref, watch, onMounted } from 'vue'
import { httpRequests } from '../helpers/HttpRequests'
import Notifications from './Notifications.vue'
import { sweetAlerts } from '../helpers/SweetAlert.js'

const emit = defineEmits(['onDelete']);
const { deleteRequest } = httpRequests()
const { sweetSuccess } = sweetAlerts()
const notifications = ref(null)
let modal = null
let confirmationText = ref('')
let disabledButton = ref(true)
let deleteApi = null

onMounted(() => {
    modal = document.querySelector('.modal-delete')
})

watch(confirmationText, (confirmation) => {
    if (confirmation.toUpperCase() == "DELETE") disabledButton.value = false
    else disabledButton.value = true
})

async function deleteRecord() {
    const response = await deleteRequest(deleteApi)

    confirmationText.value = ''

    if (response.hasErrors) return notifications.value.sendNotification('error', 'Error', response.message)

    modal.style.visibility = "hidden"
    
    sweetSuccess(response.data.message)
    
    emit('onDelete')
}

function showModal(api) {
    deleteApi = api
    modal.style.visibility = "visible"
    confirmationText.value = ''
}

function hiddenModal() {
    deleteApi = null
    modal.style.visibility = "hidden"
}

defineExpose({ showModal })
</script>