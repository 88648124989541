<template>
    <div class="table-paginator" v-if="previousPage || nextPage">
        <div class="table-paginator__buttons">
            <button class="table-paginator__previous-button" @click="previousLink">
                <img src="@/assets/icons/previous-arrow.svg" width="7" height="12" alt=""
                    class="table-paginator__img-previous-button">
                Previous
            </button>
            <div class="table-paginator__current-page">
                Page {{ currentPage }} Of {{ lastPage }}
            </div>
            <button class="table-paginator__next-button" @click="nextLink">
                Next
                <img src="@/assets/icons/next-arrow.svg" width="7" height="12" alt=""
                    class="table-paginator__img-next-button">
            </button>
        </div>
        <select class="table-paginator__select-page" @change="updatePage">
            <option :selected="page.url == currentPageLink" :value="page.url" v-for="page in pagesLinks"
                :key="page.number">
                Page {{ page.number }}
            </option>
        </select>
    </div>

    <Notifications ref="notifications" />

    <!-- Content loader -->
    <ContentLoader :hidden-loader="hiddenLoader" />
</template>

<script setup>
import { ref, onMounted, watch } from 'vue'
import { httpRequests } from '../helpers/HttpRequests'
import Notifications from './Notifications.vue'
import ContentLoader from '@/common/ContentLoader.vue'

const props = defineProps(['apiUrl', 'filter', 'modelValue'])
const emit = defineEmits(['update:modelValue'])
const nextPage = ref('')
const previousPage = ref('')
const currentPage = ref(null)
const lastPage = ref(null)
const { getRequest } = httpRequests()
const notifications = ref(null)
const hiddenLoader = ref(false)
const pagesLinks = ref([])
const currentPageLink = ref('')

onMounted(() => {
    getApiData()
});

async function getApiData(link) {
    const requestData = await getRequest(link ?? props.apiUrl)

    // * Hidden content loader
    hiddenLoader.value = true

    if (requestData.hasErrors) {
        notifications.value.sendNotification('error', 'Error', requestData.message)
        return console.error(`Paginator server error: ${link ?? props.apiUrl}`)
    }

    // * Get request data
    const apiData = requestData.data

    // * Set current page link
    currentPageLink.value = link ?? props.apiUrl

    // * Set next page
    nextPage.value = apiData.next_page_url ?? null

    // * Set previous page
    previousPage.value = apiData.prev_page_url ?? null

    // * Set current page
    currentPage.value = apiData.current_page

    // * Set last page
    lastPage.value = apiData.last_page

    // * Set pages links
    for (let index = 1; index <= lastPage.value; index++) {
        const page = { number: index, url: `${props.apiUrl}?page=${index}` }
        const findPage = pagesLinks.value.find(pg => pg.number === index)

        if (findPage === undefined) pagesLinks.value.push(page)
    }

    return emit('update:modelValue', apiData.data)
}

function nextLink() {
    if (nextPage.value) {
        const nexPageLink = nextPage.value.split(`${process.env.VUE_APP_BASE_PATH}/api`)[1]
        getApiData(nexPageLink)
    } 
}

function previousLink() {
    if (previousPage.value) {
        const previousPageLink = previousPage.value.split(`${process.env.VUE_APP_BASE_PATH}/api`)[1]
        getApiData(previousPageLink)
    } 
}

function refreshPage() {
    getApiData(currentPageLink.value)
}

function updatePage(page) {
    const selectedPage = page.target.value
    getApiData(selectedPage)
}

watch(
    () => props.filter,
    (words) => {
        // * Make path with filter words
        const path = `${props.apiUrl}?query=${words}`

        // * Get api data
        getApiData(path);
    }
)

defineExpose({ refreshPage })
</script>